<template>
  <section>
    <v-data-table
      :headers="headers"
      :items="collections"
      :items-per-page="-1"
      hide-default-footer
    >
      <template v-slot:item.collection="{ item }">
        <section class="d-md-flex align-center">
          <v-avatar class="mr-md-4 mb-3 mb-md-0">
            <v-img
              :src="item.image"
              width="100"
              height="100"
            />
          </v-avatar>
          <section class="text-left">
            <p class="subtitle-2 text--secondary mb-0">1 item</p>
            <p class="text-h6 font-weight-bold mb-0">Uniswap v3 Positions</p>
            <p class="subtitle-2 text--secondary mb-0">Floor Price: 0 Eth.</p>
          </section>
        </section>
      </template>
      <template v-slot:item.minimumOffer="{ item }">
        <section class="py-4">
          <v-text-field
            v-model.number="item.minimumBidThreshold"
            outlined
            hide-details
            single-line
            type="number"
            class="disable-prepend-inner-margin text-left mb-1 mt-3"
            placeholder="0"
          >
            <template v-slot:prepend-inner>
              <input-prepended-currency currency="eth" icon-only />
            </template>
          </v-text-field>
          <p class="subtitle-2 body-2 text--secondary text-left mb-0">$0 USD</p>
        </section>
      </template>
    </v-data-table>
    <section class="text-right">
      <v-btn color="primary ml-auto">Save</v-btn>
    </section>
  </section>
</template>

<script>
import faker from 'faker';

const image = faker.image.image();

export default {
  name: 'ProfileOfferSettingsForm',
  data: () => ({
    headers: [
      {
        text: 'Collection',
        align: 'left',
        value: 'collection',
      },
      {
        text: 'Minimum Offer',
        align: 'left',
        value: 'minimumOffer',
      },
    ],
    collections: [
      {
        itemsCount: 1,
        title: 'UniSwap v3 Positions',
        image,
        verified: true,
        ethFloorPrice: 0.00,
        ethMinimumOffer: 0,
      },
      {
        itemsCount: 1,
        title: 'Official Peace Collection',
        image,
        verified: false,
        floorPrice: 0.01,
        ethMinimumOffer: 1,
      },
    ],
  }),
};
</script>
